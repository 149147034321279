import React from "react";

function Footer() {
    return (
        <footer className="text-center p-3">
            <p>2023 | COMP2112 | Jason Williams | 200523495</p>
            <a href="https://linkedin.com/in/jason-williams-9818a3294"><i className="bi bi-linkedin"></i></a>
            <a href="https://github.com/MonsieurJAWs?tab=overview&from=2023-10-01&to=2023-10-02"><i className="bi bi-github"></i></a>
        </footer>
    );
};

export default Footer;
