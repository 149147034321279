import React, { useState, useEffect } from "react";


// Import Images from Img folder
import GitHubLogo from "../img/GitHub_Invertocat_Logo.png";
import CSharpLogo from "../img/Csharp_Logo.png";
import JSLogo from "../img/JavaScript-logo.png";
import NetCoreLogo from "../img/NET_Core_Logo.svg";
import ReactLogo from "../img/React-icon.svg.png";
import VSCodeLogo from "../img/visualStudioCodeLogo.png";

// Define API as per documentation
const url = 'https://weatherapi-com.p.rapidapi.com/current.json?q=Barrie';
const options = {
	method: 'GET',
	headers: {
		'X-RapidAPI-Key': 'fb6fa8e27cmsh1357852ff4589afp13c2dbjsn2d81f9654e8f',
		'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
	}
};

function Skills() {
    
    const [temp, setTemp] = useState([]);
    useEffect(() => {
        // get the api data
        const fetchData = async () => {
            try {
                const response = await fetch(url, options)
                response.json().then(json => {
                    // set the json data of the current temp to the temp using useState
                    setTemp(json.current.temp_c);
                })
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[]);

    return (
        <section>
            <div className="row m-3">
                <div className="col-lg-4 text-center p-3">
                    <img src={GitHubLogo} className="rounded mx-auto d-block" height="100" width="100" alt="GitHub Logo" />
                    <h2 className="fw-normal">GitHub</h2>
                    <p>2022 - Current.</p>
                </div> {/* end of .col */}
                <div className="col-lg-4 text-center">
                    <img src={CSharpLogo} className="rounded mx-auto d-block" height="100" width="100" alt="C# Logo" />
                    <h2 className="fw-normal">C#</h2>
                    <p>2022 - Current</p>
                </div> {/* end of .col */}
                <div className="col-lg-4 text-center p-3">
                    <img src={NetCoreLogo} className="rounded mx-auto d-block" height="100" width="100" alt=".Net Core Logo" />
                    <h2 className="fw-normal">.NetCore</h2>
                    <p>2022 - Current</p>
                </div> {/* end of .col */}
                <div className="col-lg-4 text-center p-3">
                    <img src={JSLogo} className="rounded mx-auto d-block" height="100" width="100" alt="JavaScript Logo" />
                    <h2 className="fw-normal">JavaScript</h2>
                    <p>2023 - Current.</p>
                </div> {/* end of .col */}
                <div className="col-lg-4 text-center p-3">
                    <img src={ReactLogo} className="rounded mx-auto d-block" height="100" width="100" alt="React Logo" />
                    <h2 className="fw-normal">React</h2>
                    <p>2023 - Current.</p>
                </div> {/* end of .col */}
                <div className="col-lg-4 text-center p-3">
                    <img src={VSCodeLogo} className="rounded mx-auto d-block" height="100" width="100" alt="Visual Studio Code Logo" />
                    <h2 className="fw-normal">Visual Studio Code</h2>
                    <p>2023 - Current.</p>
                </div> {/* end of .col */}
            </div> {/* end of .row */}
            <div className="text-center">
                <h2>Weather API</h2>
                {/* Display the temp as defined from the api and useState */}
                <p>Current Temperature in Barrie ON is {temp} C. </p>
            </div>
        </section>
    );
};

export default Skills;

/* Sourced from:
https://rapidapi.com/weatherapi/api/weatherapi-com

https://www.youtube.com/watch?v=ZRFwuGpiLl4&t=107s

*/