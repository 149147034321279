import React from "react";

// Add Personal Image to be used in the About me section
import PersonalImage from "../img/IMG_20180929_175803.jpg";

function About() {
    return (
        <section className="container-fluid">
            <div className="row">
                <div className="col-sm">
                    <h1 className="fw-light">A little About Me</h1>
                    <p className="fs-5 lh-lg lead text-body-secondary">One thing that's always interested me was the design and layout of websites. Over the years, I’ve watched websites change from simple text-based web pages to these massive web applications. From the outside looking in, I’ve always wanted to know how these are put together. During the last few years, I decided it was time for a career change and to make learning the inner workings of web development a reality. Over the last two years, I’ve committed myself to learning the tools of HTML, CSS, and JavaScript in the hope to create responsive, user-friendly, and visually appealing web applications for users and customers alike.<br>
                    </br>
                    -Jason Williams
                    </p>
                </div> {/* end of .col-sm */}
                <div className="col-sm">
                    <figure>
                        <img className="img-fluid mx-auto d-block" src={PersonalImage} width="85%" alt="Personal Logo" />
                    </figure>
                </div> {/* end of .col-sm */}
            </div> {/* end of .row */}
        </section>
    );
};

export default About;