import React from "react";

function Home() {
    return (
        <section>
            {/* Create a Hero image for the homepage */}
            <div className="p-5 text-center bg-image rounded-3">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-white">
                        <h1 className="mb-3">I'm Jason Williams</h1>
                        <h4 className="mb-3">Designer and Developer</h4>
                        {/* Link the welcome button to the About page */}
                        <a href="/about" className="btn btn-outline-light" id="about-link">Welcome</a>
                    </div> {/* .end text-white */}
                </div> {/* .end of d-flex */}
            </div> {/* .end of p-5 */}
            <p className="missionStatement text-center lead text-body-secondary">Learning to master the tools of HTML, CSS, and JavaScript using React to create responsive, user friendly, and visually appealing web applications, suiting the needs of clients and customers alike.</p>
        </section>
    );
};

export default Home;