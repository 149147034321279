// Use state for the contact form
import React, { useState } from "react";

// Use the react-hook-form for validation after adding the npm package
import { useForm } from "react-hook-form"; 

function Contact() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    // register inputs for validation
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <section className="container">
            <h1 className="fw-light text-center pt-5">Feel Free to Contact Me</h1>
            {/* prevent default on form submit through validation. Also, add an alert that displays the content of the message and mention that the email was submitted */}
            <form className="row g-3" onSubmit={handleSubmit(() =>{
                alert(`"${firstName} ${lastName}", the message containing "${message}" has been sent successfully via this email "${email}".`);
            })}>
                <div className="col">
                    {/* Need to capture the user input for the form using an onChange. This will be done to each input element. Also, each input is tied to form validation */}
                    <input {...register("firstName", {required: `First Name is required` })} type="text" className="form-control" placeholder="First name" aria-label="First name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    {/* Display an error message if the user tries to submit with out entering content */}
                    <p>{errors.firstName?.message}</p>
                </div> {/* end of .col */}
                <div className="col">
                    <input {...register("lastName", {required: `Last Name is required` })} type="text" className="form-control" placeholder="Last name" aria-label="Last name" value={lastName} onChange={e => setLastName(e.target.value)} />
                    <p>{errors.lastName?.message}</p>
                </div> {/* end of .col */}
                <div className="mb-3">
                    <input {...register("email", {required: `Email is required`})} type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                    <p>{errors.email?.message}</p>
                </div> {/* end of .mb-3 */}
                <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Enter Message Here</label>
                    <textarea {...register("message", {required: `A message is required`})} className="form-control" id="exampleFormControlTextarea1" rows="3" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                    <p>{errors.message?.message}</p>
                </div> {/* end of .mb-3 */}
                <div className="col-12">
                    <button className="btn btn-secondary" type="submit">Submit</button>
                </div> {/* end of .col-12 */}
            </form> {/* end of .row */}
        </section>
    );
};

export default Contact;

/* Used multiple sources to better understand and implement form validation with useState in react

https://www.react-hook-form.com/get-started/
https://www.locofy.ai/blog/react-form-validation
https://blog.devgenius.io/implementing-a-contact-form-using-react-hooks-c30ec4aa59ab 

*/