import React from "react";

// Import Images from Img folder
import RoadWellTravelled from "../img/roadWellTravelledScreenshot.png";
import StyleStage from "../img/StyleStage_Screenshot.png";
import JudgingABook from "../img/JudgingABookScreenshot.png";

function Projects() {
    return (
        <section>
            <div>
                <section className="py-5 text-center container">
                    <div className="row py-lg-5">
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <h1 className="fw-light">My Projects</h1>
                            <p className="lead text-body-secondary">Over the last two years I have worked on a few projects. Here you can view some of my favourites. </p>
                            <p>
                                {/* Link the welcome button to the About page */}
                                    <a href="/About" className="btn btn-secondary mx-3" id="about-link">About</a>
                                {/* Link the welcome button to the About page */}
                                    <a href="/Contact" className="btn btn-secondary mx-3" id="about-link">Contact</a>
                            </p>
                        </div> {/* end of .col */}
                    </div> {/* end of .row */}
                </section>
                <div className="album py-5">
                    <div className="container">
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            <div className="col">
                                <figure className="card shadow-sm">
                                    <img src={RoadWellTravelled} alt="The Road Well Travelled" />
                                    <figcaption className="card-body">
                                        <p className="card-text">Using CSS to recreate a magazine article clipping into HTML as close to 1-1 as possible</p>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group">
                                                <a href="https://lamp.computerstudi.es/~Jason200523495/Assignment3_JasonWilliams_200523495/assignment3-layouts-and-html/road-well-travelled.html"><button type="button" className="btn btn-sm btn-outline-secondary">View project</button></a>
                                            </div> {/* end of .btn-group */}
                                        </div> {/* end of .d-flex */}
                                    </figcaption> {/* end of .card-body */}
                                </figure> {/* end of .card shadow-sm */}
                            </div> {/* end of .col */}
                            <div className="col">
                                <figure className="card shadow-sm">
                                    <img src={StyleStage} alt="CSS Style Stage" />
                                    <figcaption className="card-body">
                                        <p className="card-text">The idea of the project was to create a responsive layout without the use of any libraries or altering the HTML.</p>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group">
                                                <a href="https://lamp.computerstudi.es/~Jason200523495/Project_JasonWilliams_200523495/stylestage.html" ><button type="button" className="btn btn-sm btn-outline-secondary">View project</button></a>
                                            </div> {/* end of .btn-group */}
                                        </div> {/* end of .d-flex */}
                                    </figcaption> {/* end of .card-body */}
                                </figure> {/* end of .card shadow-sm */}
                            </div> {/* end of .col */}
                            <div className="col">
                                <figure className="card shadow-sm">
                                    <img src={JudgingABook} alt="A mock book store called Comic Zone" />
                                    <figcaption className="card-body">
                                        <p className="card-text">Learning Bootstrap throughout the semester. Here is a store page layout created using what I have learned so far.</p>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group">
                                                <a href="https://lamp.computerstudi.es/~Jason200523495/Assignment2_JasonWilliams_200523495/" ><button type="button" className="btn btn-sm btn-outline-secondary">View project</button></a>
                                            </div> {/* end of .btn-group */}
                                        </div> {/* end of .d-flex */}
                                    </figcaption> {/* end of .card-body */}
                                </figure> {/* end of .card shadow-sm */}
                            </div> {/* end of .col */}
                        </div> {/* end of .row */}
                    </div> {/* end of .row */}
                </div> {/* end of .row */}
            </div> {/* end of .row */}
        </section>
    );
};

export default Projects;